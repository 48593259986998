import SideMenuContext from '@/context/sideMenuContext';
import { GetSystemOptionsResponse, GET_SYSTEM_OPTIONS } from '@/graphql/administration';
import {
  CopilotConversationMessageResponse,
  CopilotConversationTitlesResponse,
  LoadMessageContextResponse,
  LOAD_MESSAGE_CONTEXT,
  QUERY_COPILOT_CONVERSATION_MESSAGES,
  QUERY_COPILOT_CONVERSATION_TITLES,
  RemoveMessageContextResponse,
  REMOVE_MESSAGE_CONTEXT,
  SEND_COPILOT_MESSAGE
} from '@/graphql/coPilot';
import { SystemOptionKeyEnum } from '@/types/administration';
import { CopilotConversationMessage, CopilotConversationTitle, SenderType } from '@/types/coPilot';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { categorizeDate } from '@/util/date';
import { useCopilotMessageStore } from '@/zustand/CoPilotMessageStore';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { BorderColor, CloseFullscreen, OpenInFull } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Alert, Card, CardActions, CardHeader, CircularProgress, Skeleton, Stack, TextField } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useParams } from 'react-router-dom';
import Button from '../Button';
import MuiAccordion from '../MuiAccordion';
import TypingIndicator from '../TypingIndicator';
import { Small, Subtitle } from '../Typography';
import CoPilotMessage from './CoPilotMessage';

type Props = {
  title: string;
  onClose: () => void;
};

interface ICategorizedConvoTitles {
  [category: string]: CopilotConversationTitle[];
}

const CoPilotWindow = ({ title, onClose }: Props) => {
  const { id } = useParams();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const [convoHistoryTitles, setConvoHistoryTitles] = useState<CopilotConversationTitle[]>([]);
  const [convoHistoryMessages, setConvoHistoryMessages] = useState<CopilotConversationMessage[]>([]);
  const messages = useCopilotMessageStore(state => state.messages);
  const addMessage = useCopilotMessageStore(state => state.addMessage);
  const clearMessages = useCopilotMessageStore(state => state.clearMessages);
  const processingMessage = useCopilotMessageStore(state => state.processingMessage);

  const [input, setInput] = useState<string>('');
  const [contextLoadError, setContextLoadError] = useState<boolean>(false);
  const [sendMessageLoading, setSendMessageLoading] = useState<boolean>(false);
  const [isWindowExpanded, setIsWindowExpanded] = useState(false);
  const [isConvoHistorySelected, setIsConvoHistorySelected] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  const { drawerOpen } = useContext(SideMenuContext);

  const [loadMessageContext] = useMutation<LoadMessageContextResponse>(LOAD_MESSAGE_CONTEXT, {
    variables: {
      truentityId: id,
      relyingPartyId: currentUser?.relyingParty?.id
    },
    onError: error => {
      console.error('Error loading message context:', error.message);
      setContextLoadError(true);
    }
  });

  const [removeMessageContext] = useMutation<RemoveMessageContextResponse>(REMOVE_MESSAGE_CONTEXT, {
    variables: {
      truentityId: id
    },
    onError: error => {
      console.error('Error removing the message context: ', error.message);
    }
  });

  const [sendMessage] = useMutation(SEND_COPILOT_MESSAGE, {
    onCompleted: () => {
      setSendMessageLoading(false);
    },
    onError: error => {
      console.error('Send Message Error:', error.message);
      addMessage({
        sender: SenderType.BOT,
        text: 'There was an error sending your message. Please try again later. :disappointed:'
      });
      setSendMessageLoading(false);
    }
  });

  const { loading: convoHistoryTitlesLoading, refetch: refetchCopilotTitles } = useQuery<CopilotConversationTitlesResponse>(
    QUERY_COPILOT_CONVERSATION_TITLES,
    {
      variables: {
        truentityId: id
      },
      onCompleted: data => {
        const convoHistoryTitlesData = data?.getAccountCoPilotConversationTitles;
        if (Array.isArray(convoHistoryTitlesData)) {
          console.log(convoHistoryTitlesData);
          setConvoHistoryTitles(convoHistoryTitlesData);
        }
      },
      onError: error => {
        console.error(error);
      }
    }
  );

  // The following useMemo state is a date categorized list of the conversation history
  const categorizedConvoHistoryTitles = useMemo(() => {
    const categorized: ICategorizedConvoTitles = {};

    convoHistoryTitles.forEach(conversation => {
      const category = categorizeDate(new Date(conversation?.createdAt));
      if (!categorized[category]) {
        categorized[category] = [];
      }
      categorized[category].push(conversation);
    });

    // Define the predefined order
    const predefinedOrder = ['Today', 'Yesterday', 'Earlier this month', 'Last month', 'Older'];

    // Sort the categories based on the predefined order
    const sortedCategorized = {} as ICategorizedConvoTitles;

    predefinedOrder.forEach(category => {
      if (categorized[category]) {
        sortedCategorized[category] = categorized[category];
      }
    });

    return sortedCategorized;
  }, [convoHistoryTitles]);

  const [getAccountCoPilotConversations, { loading: convoMessagesLoading }] = useLazyQuery<CopilotConversationMessageResponse>(
    QUERY_COPILOT_CONVERSATION_MESSAGES,
    {
      onCompleted: data => {
        if (Array.isArray(data?.getAccountCoPilotConversations)) {
          setConvoHistoryMessages(data?.getAccountCoPilotConversations);
        }
      },
      onError: error => {
        console.error(error);
      }
    }
  );
  const {
    data: systemOptionsData,
    error: systemOptionsError,
    loading
  } = useQuery<GetSystemOptionsResponse>(GET_SYSTEM_OPTIONS, {
    variables: {
      optionKey: SystemOptionKeyEnum.RPM_CO_PILOT_SUGGESTIONS
    },
    fetchPolicy: 'cache-first'
  });

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (systemOptionsError) {
      enqueueSnackbar('Could not fetch Suggestions', {
        variant: 'error'
      });
    }
  }, [systemOptionsError]);

  const transformedOptions = useMemo(() => {
    if (!systemOptionsData?.getSystemOptions?.options) return [];

    return systemOptionsData.getSystemOptions.options.map(option => ({
      category: option.value,
      suggestions: option.nestedOptions?.map(nestedOption => ({
        title: nestedOption.value
      }))
    }));
  }, [systemOptionsData]);

  const handleSend = () => {
    if (!input.trim()) {
      return;
    }

    if (Array.isArray(messages)) {
      sendMessage({
        variables: {
          truentityId: id,
          relyingPartyId: currentUser?.relyingParty.id,
          message: input
        }
      }).catch(error => {
        console.error('Error caught in handleSend:', error.message);
      });
    }

    addMessage({ sender: SenderType.USER, text: input });
    setInput('');
    setSendMessageLoading(true);
  };

  const handleClose = () => {
    onClose();
  };

  const onSuggestionSelected = suggestion => {
    setInput(suggestion.title);
  };

  const handleAccordionChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    loadMessageContext();

    //Use a cleanup function to remove the message context based on the component unmount
    return () => {
      removeMessageContext();
      clearMessages();
    };
  }, [loadMessageContext]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    // listen to message changes and do required reftches
    /*
     * TODO : the following condition to refetch the titles is temporary
     * Refactor the logic once a webhook event is created to notify the creation of a title
     */
    if (Array.isArray(messages) && messages.length >= 2 && messages.length <= 4) {
      refetchCopilotTitles();
    }
  }, [messages]);

  const handleExpand = () => {
    isWindowExpanded ? setIsWindowExpanded(false) : setIsWindowExpanded(true);
  };

  const onClickConversationTitle = async (titleId: string) => {
    // setIsNewChat(false);
    setIsConvoHistorySelected(true);
    clearMessages();
    await getAccountCoPilotConversations({
      variables: {
        truentityId: id,
        titleId: titleId
      }
    });
  };

  const accordionOptions = transformedOptions.map((option, index) => ({
    label: option.category,
    defaultExpand: false,
    content: (
      <Stack rowGap={1}>
        {option.suggestions?.map((suggestion, suggestionIndex) => (
          <Button
            key={suggestionIndex}
            variant="outlined"
            color="primary"
            onClick={() => onSuggestionSelected(suggestion)}
            sx={{ fontSize: '0.875rem', flexShrink: 0, textTransform: 'capitalize' }}
          >
            {suggestion.title}
          </Button>
        ))}
      </Stack>
    ),
    expanded: expanded === `category${index}`,
    onChange: handleAccordionChange(`category${index}`)
  }));

  const drawerWidth = drawerOpen ? '360px' : '72px';

  const onClickNewChat = () => {
    // setIsNewChat(true);
    setIsConvoHistorySelected(false);
    removeMessageContext();
    clearMessages();
  };

  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      position={isWindowExpanded ? { x: 0, y: 0 } : undefined}
      disabled={isWindowExpanded}
    >
      <Card
        elevation={14}
        sx={{
          zIndex: theme.zIndex.drawer + 20,
          position: 'fixed',
          bottom: isWindowExpanded ? 0 : 10,
          right: isWindowExpanded ? 0 : 40,
          width: isWindowExpanded ? `calc(100% - ${drawerWidth})` : 720,
          height: isWindowExpanded ? `calc(100% - 85px)` : 590,
          background: '#fff',
          display: 'flex'
        }}
      >
        <Stack direction="row" flexGrow={1}>
          <Stack
            padding={1.5}
            flexShrink={0}
            flexDirection="column"
            flexBasis={280}
            maxWidth={280}
            bgcolor={theme.palette.background.paper}
            gap={1}
          >
            <Button
              sx={{
                padding: '5px 15px',
                fontSize: '0.875rem',
                borderWidth: '1px',
                my: '0.5rem'
              }}
              variant="outlined"
              size="small"
              label="New Chat"
              onClick={onClickNewChat}
              startIcon={<BorderColor fontSize="small" />}
              disabled={!isConvoHistorySelected && Array.isArray(messages) && messages.length === 0}
            />
            {convoHistoryTitlesLoading ? (
              <Stack direction="column" gap={3}>
                {[...Array(5)].map((_, index) => (
                  <Stack gap={1} key={index}>
                    <Skeleton variant="rounded" width="100%" height={20} animation="wave" />
                    <Skeleton variant="rounded" width="75%" height={15} animation="wave" />
                  </Stack>
                ))}
              </Stack>
            ) : (
              <Stack direction="column" gap={1} overflow="auto">
                {Object.entries(categorizedConvoHistoryTitles).map(([category, convoTitleItems]) => (
                  <Stack key={category}>
                    <Subtitle sx={{ fontSize: 'sm' }}>{category}</Subtitle>
                    {convoTitleItems.map((conversationTitle, index) => (
                      <Button
                        key={index}
                        variant="text"
                        component="div"
                        sx={{
                          textTransform: 'none',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          textAlign: 'left',
                          p: 1,
                          width: '100%'
                        }}
                        onClick={() => onClickConversationTitle(conversationTitle?.id)}
                      >
                        <Box fontSize="0.875rem" width="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                          {conversationTitle?.title}
                        </Box>
                        <Small>{conversationTitle?.feature}</Small>
                      </Button>
                    ))}
                  </Stack>
                ))}
              </Stack>
            )}
          </Stack>
          <Stack flexDirection="column" flexGrow={1}>
            <CardHeader
              title={title}
              id="draggable-dialog-title"
              action={
                <Stack direction="row">
                  <IconButton aria-label="Close" onClick={handleExpand}>
                    {isWindowExpanded ? <CloseFullscreen fontSize="small" /> : <OpenInFull fontSize="small" />}
                  </IconButton>
                  <IconButton aria-label="Close" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Stack>
              }
              sx={{ p: 2, cursor: isWindowExpanded ? 'auto' : 'move', borderBottom: 1, borderColor: theme => theme.palette.grey[300] }}
            />
            <CardContent
              sx={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                flexGrow: 1,
                padding: 1,
                gap: 1.5,
                overflowY: 'auto'
              }}
            >
              {isConvoHistorySelected && (
                <>
                  {convoMessagesLoading && (
                    <>
                      {[...Array(4)].map((_, index) => (
                        <Stack gap={1} key={index}>
                          <Skeleton
                            variant="rounded"
                            width={Math.floor(Math.random() * (150 - 50 + 1)) + 180} // Generate a random width for the conversation skeleton
                            height={50}
                            animation="wave"
                            sx={{ alignSelf: 'end', borderRadius: '20px 0 20px 20px' }}
                          />
                          <Skeleton
                            variant="rounded"
                            width="80%"
                            height={(index + 1) * 70}
                            animation="wave"
                            sx={{ borderRadius: '0 20px 20px 20px' }}
                          />
                        </Stack>
                      ))}
                    </>
                  )}
                  {Array.isArray(convoHistoryMessages) &&
                    convoHistoryMessages.map((message, index) => (
                      <CoPilotMessage
                        key={index}
                        message={{ sender: message.messageType, text: message.message }}
                        sendMessageLoading={false}
                        isLastMessage={index === convoHistoryMessages.length - 1}
                      />
                    ))}

                  <Alert
                    severity="info"
                    sx={{
                      backgroundColor: 'grey.100',
                      color: 'grey.800',
                      border: '1px solid',
                      borderColor: 'grey.400',
                      my: '1rem'
                    }}
                  >
                    End of conversation. Click on New Chat to start a new conversation
                  </Alert>
                </>
              )}

              {!isConvoHistorySelected && Array.isArray(messages) && messages.length > 0 ? (
                messages.map((message, index) => (
                  <CoPilotMessage
                    key={index}
                    message={message}
                    sendMessageLoading={sendMessageLoading}
                    isLastMessage={index === messages.length - 1}
                  />
                ))
              ) : loading ? (
                <Stack justifyContent="center" direction="row" sx={{ width: '100%', mt: 1 }}>
                  <CircularProgress />
                </Stack>
              ) : (
                accordionOptions?.length > 0 &&
                !isConvoHistorySelected && (
                  <>
                    <Small sx={{ color: theme.palette.grey[500] }}>Suggestions</Small>
                    <MuiAccordion
                      sx={{
                        borderRadius: 2,
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        background: `linear-gradient(145deg, ${theme.palette.background.paper}, #ffffff)`,
                        '&:before': {
                          display: 'none'
                        }
                      }}
                      options={accordionOptions}
                      disableGutters
                    />
                  </>
                )
              )}
              <div ref={messagesEndRef} />
            </CardContent>
            <CardActions sx={{ p: 1 }}>
              <Stack width="100%" display={isConvoHistorySelected ? 'none' : 'block'}>
                <TypingIndicator isTyping={!!processingMessage} label={processingMessage} />

                <Stack
                  direction="row"
                  component="form"
                  onSubmit={e => {
                    e.preventDefault();
                    handleSend();
                  }}
                  alignItems="center"
                  flexGrow={1}
                >
                  <TextField
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    placeholder={contextLoadError ? 'Error loading context. Try again later.' : 'Type a message...'}
                    variant="outlined"
                    fullWidth
                    sx={{ marginRight: '8px' }}
                    disabled={contextLoadError || sendMessageLoading}
                  />
                  <IconButton type="submit" disabled={!input.trim() || sendMessageLoading || contextLoadError}>
                    <SendIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </CardActions>
          </Stack>
        </Stack>
      </Card>
    </Draggable>
  );
};

export default CoPilotWindow;
