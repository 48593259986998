import type { GetAccountFollowupByIdResponse } from '@/graphql/account';
import { FollowUpCategoryTypes, FollowUpStatusTypes, GET_ACCOUNT_FOLLOWUP_BY_ID, UPDATE_ACCOUNT_FOLLOWUP } from '@/graphql/account';
import FollowUpImmediateActionsFiled from '@/routes/PatientDetails/FollowUps/Components/FollowUpImmediateActionsFiled';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { getAccountUserFullName } from '@/util/account';
import { formatDate } from '@/util/format';
import { getAlertTypeStyles } from '@/util/rpm';
import { convertToTitleCase } from '@/util/string';
import { useMutation, useQuery } from '@apollo/client';
import { CheckCircleOutline, ErrorOutlineRounded } from '@mui/icons-material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AccessibleIcon from '@mui/icons-material/Accessible';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DescriptionIcon from '@mui/icons-material/Description';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import RateReviewIcon from '@mui/icons-material/RateReview';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import { Box, Chip, CircularProgress, DialogActions, DialogContent, Grid, Paper, Stack } from '@mui/material';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useState } from 'react';
import Button from '../Button';
import InfoCard from '../Cards/InfoCard';
import { H4 } from '../Typography';
import AddActivityDialog from './AddActivityDialog';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  followUpId: string;
  onCompleted: () => void;
};

const FollowUpTaskInfoDialog: React.FC<Props> = ({ title, hideDialog, followUpId, onCompleted: onFollowupGetCompleted, ...props }) => {
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const [followUpData, setFollowUpData] = useState<FollowUpRemindersTypes | null>(null);

  const { loading: isFollowUpDataLoading } = useQuery<GetAccountFollowupByIdResponse>(GET_ACCOUNT_FOLLOWUP_BY_ID, {
    variables: {
      id: followUpId
    },
    onCompleted: data => {
      if (!data?.accountsFollowupReminderById) return;
      setFollowUpData(data?.accountsFollowupReminderById);
    }
  });

  const [updateAccountFollowUp] = useMutation(UPDATE_ACCOUNT_FOLLOWUP, {
    onCompleted: data => {
      const status = data?.updateAccountFollowup?.status === 'Success' ? 'success' : 'error';
      const message = data?.updateAccountFollowup?.message || 'Unexpected error occurred while updating a task';
      enqueueSnackbar(message, { variant: status });
      onFollowupGetCompleted();
    },
    onError: err => {
      const code = err.graphQLErrors?.[0]?.extensions?.code;
      const message = err.graphQLErrors?.[0]?.message;

      if (code && code === 'UNEXPECTED_CODE') {
        enqueueSnackbar(message, { variant: 'error' });
      } else {
        enqueueSnackbar('Unexpected error occurred while updating a task', { variant: 'error' });
      }
      hideDialog();
    }
  });

  const onActivitiesChanged = () => {
    updateAccountFollowUp({
      variables: {
        followUpIds: [followUpData?.id],
        followUpAssigneeIds: [followUpData?.relyingPartyAdmin.id],
        status: FollowUpStatusTypes.COMPLETED
      }
    });
  };

  const handleComplete = () => {
    const modal = showModal(AddActivityDialog, {
      title: 'RPM Care Management Activities',
      hideDialog: () => {
        modal.hide();
      },
      onActivitiesChanged: onActivitiesChanged,
      id: followUpData?.account.truentityId,
      followUpId: followUpData?.id,
      rpmEnrolledAtDate: moment(followUpData?.account.rpmEnrolledAt, 'YYYY-MM-DD HH:mm:ss'),
      isNoteRequired: true,
      defaultTimeTakenInSeconds: 120
    });
  };

  const renderAlertLabelChip = useCallback((value: string) => {
    const alertTypeStyles = getAlertTypeStyles(value);

    if (alertTypeStyles?.labelColor) {
      return (
        <Chip
          icon={
            <ErrorOutlineRounded
              sx={{
                color: `${alertTypeStyles?.labelColor} !important`
              }}
            />
          }
          size="small"
          variant="outlined"
          label={alertTypeStyles?.labelText}
          sx={{
            borderColor: alertTypeStyles?.labelColor,
            color: alertTypeStyles?.labelColor
          }}
        />
      );
    }
  }, []);

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} maxWidth="md" fullWidth={true}>
      <DialogContent>
        {!followUpData || isFollowUpDataLoading ? (
          <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
            <CircularProgress />
          </Stack>
        ) : (
          <Box>
            <Box bgcolor="white" p={2} component={Paper} elevation={0} mb={1}>
              <H4>Patient Information</H4>
              <Grid container rowSpacing={2} columnSpacing={1} mt={1}>
                <Grid item xs={6}>
                  <InfoCard Icon={AccessibleIcon} label="Patient Name" value={getAccountUserFullName(followUpData.account.user)} />
                </Grid>
              </Grid>
            </Box>
            {followUpData.category === FollowUpCategoryTypes.ALERT_REVIEW ? (
              <Box bgcolor="white" p={2} component={Paper} elevation={0} mb={1}>
                <H4>Alert Information</H4>
                <Grid container rowSpacing={2} columnSpacing={1} mt={1}>
                  <Grid item xs={6}>
                    {followUpData.rpmAlert?.label && (
                      <InfoCard Icon={ChecklistIcon} label="Alert Type" value={renderAlertLabelChip(followUpData.rpmAlert?.label)} />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <InfoCard Icon={MonitorHeartIcon} label="Reading" value={followUpData.rpmAlert?.reading} />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoCard Icon={DescriptionIcon} label="Description" value={followUpData.rpmAlert?.description} />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoCard Icon={CalendarMonthIcon} label="Recorded At" value={formatDate(followUpData.rpmAlert?.recordedAt)} />
                  </Grid>
                </Grid>
              </Box>
            ) : (
              followUpData.category === FollowUpCategoryTypes.CHART_REVIEW && (
                <Box bgcolor="white" p={2} component={Paper} elevation={0} mb={1}>
                  <H4>Chart Information</H4>
                  <Grid container rowSpacing={2} columnSpacing={1} mt={1}>
                    <Grid item xs={6}>
                      <InfoCard
                        Icon={AssessmentIcon}
                        label="Chart Month"
                        value={followUpData.rpmAccountReviewNote?.rpmAccountReviewWorkflow?.rpmReport?.monthYear}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InfoCard Icon={RateReviewIcon} label="Review Note" value={followUpData.rpmAccountReviewNote?.note} />
                    </Grid>
                    <Grid item xs={6}>
                      <InfoCard
                        Icon={AccessAlarmIcon}
                        label="Review Note Created At"
                        value={formatDate(followUpData.rpmAccountReviewNote?.createdAt)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )
            )}
            <Box bgcolor="white" p={2} component={Paper} elevation={0} mb={1}>
              <H4>Follow-up Task Information</H4>
              <Grid container rowSpacing={2} columnSpacing={1} mt={1}>
                <Grid item xs={6}>
                  <InfoCard Icon={ChecklistIcon} label="Follow-up Type" value={followUpData.type} />
                </Grid>

                <Grid item xs={6}>
                  <InfoCard
                    Icon={AdminPanelSettingsIcon}
                    label="Assigned By"
                    value={getAccountUserFullName(followUpData.createdByRelyingPartyAdmin?.user)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InfoCard Icon={AdminPanelSettingsIcon} label="Follow-up Task Due At" value={formatDate(followUpData.followUpOn)} />
                </Grid>
                <Grid item xs={6}>
                  <InfoCard
                    Icon={TypeSpecimenIcon}
                    label="Follow-up Task Status"
                    value={
                      <Chip
                        label={followUpData.status}
                        size="small"
                        variant="outlined"
                        color={followUpData.status === FollowUpStatusTypes.COMPLETED ? 'success' : 'info'}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InfoCard Icon={TypeSpecimenIcon} label="Follow-up Task Category" value={convertToTitleCase(followUpData.category)} />
                </Grid>
                {Array.isArray(followUpData.immediateActions) && followUpData.immediateActions && (
                  <Grid item xs={12}>
                    <InfoCard
                      Icon={TextSnippetIcon}
                      label="Follow-up Task Recommended Actions"
                      value={<FollowUpImmediateActionsFiled immediateActions={followUpData.immediateActions} />}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <InfoCard Icon={TextSnippetIcon} label="Follow-up Task Note" value={followUpData.notes} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
        <DialogActions>
          <Button variant="outlined" onClick={hideDialog}>
            Cancel
          </Button>
          <Button
            startIcon={<CheckCircleOutline />}
            color="success"
            onClick={() => {
              handleComplete();
            }}
          >
            Complete
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default FollowUpTaskInfoDialog;
