import { Message, SenderType } from '@/types/coPilot';
import { SmartToyOutlined } from '@mui/icons-material';
import { Avatar, Box, CircularProgress, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import Markdown from 'react-markdown';

interface CoPilotMessageProps {
  message: Message;
  sendMessageLoading?: boolean;
  isLastMessage?: boolean;
}

const CoPilotMessage: React.FC<CoPilotMessageProps> = ({ message, sendMessageLoading, isLastMessage }) => {
  const theme = useTheme();
  return (
    <Stack direction={message.sender === SenderType.BOT ? 'row' : 'row-reverse'} spacing={1}>
      {message?.sender === SenderType.BOT && (
        <Avatar sx={{ bgColor: 'blue', width: 30, height: 30 }}>
          <SmartToyOutlined fontSize="small" />
        </Avatar>
      )}

      <Box
        sx={{
          padding: 1,
          borderRadius: message.sender === SenderType.USER ? '20px 0 20px 20px' : '0 20px 20px 20px',
          backgroundColor: message.sender === SenderType.BOT ? theme.palette.secondary.dark : theme.palette.primary.dark,
          color: '#fff',
          maxWidth: '70%',
          position: 'relative',
          overflow: 'visible',
          alignItem: 'center',
          justifyContent: 'center'
        }}
      >
        <Box py="0.5rem" px="0.25rem">
          <Markdown>{message.text}</Markdown>
        </Box>

        {sendMessageLoading && message.sender === SenderType.USER && isLastMessage && (
          <CircularProgress
            size={15}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '-20px',
              marginTop: '-7.5px',
              zIndex: 1
            }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default CoPilotMessage;
