import Button from '@/components/Button';
import { H4 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import type { GetRpmReportsMonthlyResponse, MonthlyResponse } from '@/graphql/remotePatientMonitoring';
import { GET_RPM_MONTHLY_REPORTS_FOR_REVIEW, START_PATIENT_REVIEW_WORKFLOW } from '@/graphql/remotePatientMonitoring';
import useToken from '@/hooks/useToken';
import { color } from '@/styles/assets/colors';
import { Role } from '@/types/admin';
import type { GeneralUpdateResponseType } from '@/types/graphql';
import { type DynamicTemplateType } from '@/types/remotePatientMonitoring';
import { generateInputsFromTemplate, parseTemplate } from '@/util/rpm';
import { useAccountStore } from '@/zustand/AccountStore';
import { useMutation, useQuery } from '@apollo/client';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { Box, Card, CircularProgress, Stack } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useContext, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useParams } from 'react-router-dom';
import RpmPatientReviewNotesList from './RpmPatientReviewNotesList';
import RpmPatientReviewTemplate from './RpmPatientReviewTemplate';
import RpmPatientReviewWorkflowWindowAction from './RpmPatientReviewWorkflowWindowAction';
import RpmPatientReviewWorkflowWindowHeader from './RpmPatientReviewWorkflowWindowHeader';

const RpmPatientReviewWorkflowWindow: React.FC = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { patientReviewWorkflowMonthYear } = useAccountStore();
  const { roleType } = useToken();

  const [isWindowExpanded, setIsWindowExpanded] = useState(false);
  const [reportMonth, setReportMonth] = useState<MonthlyResponse | null>(null);
  const [isTemplateDrawerOpen, setIsTemplateDrawerOpen] = useState<boolean>(false);
  const [assessment, setAssessment] = useState<DynamicTemplateType | null>(null);
  const [isSignOffEnable, setIsSignOffEnable] = useState<boolean>(false);

  const isProviderAdmin = useMemo(() => roleType === Role.PROVIDER, [roleType]);

  const cardContentRef = useRef<HTMLDivElement>(null);
  const { drawerOpen } = useContext(SideMenuContext);

  const {
    data: rpmMonthlyReportsData,
    loading: loadingRpmMonthlyReports,
    refetch: reFetchRpmMonthlyReports
  } = useQuery<GetRpmReportsMonthlyResponse>(GET_RPM_MONTHLY_REPORTS_FOR_REVIEW, {
    variables: {
      truentityId: id,
      includeCurrentMonth: false
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const accountsReports = data.getRpmReportsMonthly;
      if (!reportMonth && patientReviewWorkflowMonthYear) {
        const foundReport = accountsReports.find(
          report => report.monthYearName.toLowerCase() === patientReviewWorkflowMonthYear?.toLowerCase()
        );
        return setReportMonth(prev => foundReport || prev);
      } else if (!reportMonth) {
        return setReportMonth(accountsReports[0]);
      } else {
        return setReportMonth(prev => {
          const foundReport = accountsReports.find(report => report.monthYearName === prev?.monthYearName);
          return foundReport || prev;
        });
      }
    },
    onError: err => {
      const errorMessage = err?.message ?? 'Could not fetch RPM Monthly Reports';
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    }
  });

  const [startPatientReviewWorkflow, { loading: loadingStartPatientReviewWorkflow }] = useMutation<{
    createAccountReviewWorkflow: GeneralUpdateResponseType;
  }>(START_PATIENT_REVIEW_WORKFLOW);

  const handleTemplateButton = () => {
    if (!isTemplateDrawerOpen) {
      setIsWindowExpanded(true);
    }
    setIsTemplateDrawerOpen(!isTemplateDrawerOpen);
  };

  const onSignOffCompleted = () => reFetchRpmMonthlyReports();

  const handleStartPatientReview = async () => {
    try {
      const response = await startPatientReviewWorkflow({
        variables: {
          rpmReportId: reportMonth?.report?.id
        }
      });

      if (response?.data?.createAccountReviewWorkflow?.status === 'Success') {
        enqueueSnackbar('Patient Review started successfully', {
          variant: 'success'
        });
        await reFetchRpmMonthlyReports();
      } else {
        const errorMessage = response?.errors?.[0]?.message ?? 'Something went wrong. Could not start Patient Review.';
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        enqueueSnackbar(err?.message ?? 'Something went wrong. Could not start Patient Review.', {
          variant: 'error'
        });
      } else {
        enqueueSnackbar('Something went wrong. Could not start Patient Review.', {
          variant: 'error'
        });
      }
    }
  };

  const assessmentTemplate = (templateId: number, assessmentText: string) => {
    const placeholders = parseTemplate(assessmentText);
    const inputs = generateInputsFromTemplate(placeholders);

    setAssessment({
      templateId: templateId.toString(),
      templateText: assessmentText,
      inputs: inputs
    });
  };

  const drawerWidth = drawerOpen ? '360px' : '72px';
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      position={isWindowExpanded ? { x: 0, y: 0 } : undefined}
      disabled={isWindowExpanded}
    >
      <Card
        elevation={14}
        component={Stack}
        width={isWindowExpanded ? `calc(100% - ${drawerWidth} - 5px)` : 560}
        height={isWindowExpanded ? `calc(100% - 90px)` : 600}
        position="fixed"
        bottom={isWindowExpanded ? 0 : 10}
        right={isWindowExpanded ? 0 : 40}
        sx={{
          zIndex: theme => theme.zIndex.drawer + 20,
          bgcolor: color.grey50
        }}
      >
        <RpmPatientReviewWorkflowWindowHeader
          reportMonth={reportMonth}
          isWindowExpanded={isWindowExpanded}
          rpmMonthlyReportsData={rpmMonthlyReportsData}
          isSignOffEnable={isSignOffEnable}
          setReportMonth={setReportMonth}
          setIsTemplateDrawerOpen={setIsTemplateDrawerOpen}
          setIsWindowExpanded={setIsWindowExpanded}
          onSignOffCompleted={onSignOffCompleted}
        />
        <CardContent
          ref={cardContentRef}
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            p: 0
          }}
        >
          <Box width="100%" height="100%" p={4} overflow="auto">
            {loadingRpmMonthlyReports ? (
              <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
                <CircularProgress />
              </Stack>
            ) : !reportMonth?.report?.rpmAccountReviewWorkflow?.id ? (
              <Stack justifyContent="center" alignItems="center" width="100%" height="100%" gap={2}>
                <H4>No RPM Patient Review Workflow found for this Month</H4>
                {isProviderAdmin && (
                  <Button
                    isLoading={loadingStartPatientReviewWorkflow}
                    color="primary"
                    endIcon={<PlayCircleFilledWhiteIcon />}
                    onClick={handleStartPatientReview}
                  >
                    Start Review
                  </Button>
                )}
              </Stack>
            ) : (
              <RpmPatientReviewNotesList
                accountReviewWorkflowId={reportMonth?.report?.rpmAccountReviewWorkflow?.id}
                setIsSignOffEnable={setIsSignOffEnable}
                truentityId={id}
              />
            )}
          </Box>
          <RpmPatientReviewTemplate
            isOpen={isTemplateDrawerOpen}
            monthYear={patientReviewWorkflowMonthYear}
            closeDrawer={handleTemplateButton}
            setValue={(id, templateText) => assessmentTemplate(id, templateText)}
          />
        </CardContent>
        {reportMonth?.report?.rpmAccountReviewWorkflow && (
          <RpmPatientReviewWorkflowWindowAction
            reportMonth={reportMonth}
            isTemplateDrawerOpen={isTemplateDrawerOpen}
            assessment={assessment}
            setAssessment={setAssessment}
            handleTemplateButton={handleTemplateButton}
          />
        )}
      </Card>
    </Draggable>
  );
};

export default RpmPatientReviewWorkflowWindow;
