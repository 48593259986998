import Announcement from '@/components/Announcements';
import BreadcrumbRibbonMenu from '@/components/BreadcrumbRibbonMenu';
import StarredPatientsList from '@/components/StarredPatientsList';
import type { SideMenuContextProps } from '@/context/sideMenuContext';
import SideMenuContext from '@/context/sideMenuContext';
import useScrollBarPosition from '@/hooks/useScrollBarPosition';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import type { RecentPatientType } from '@/hooks/useToken';
import useToken from '@/hooks/useToken';
import { MedicalServices, Role } from '@/types/admin';
import type { LoggedUser } from '@/types/graphql';
import { PatientsListSearchFilters } from '@/types/patients-list-filters';
import { getAccountRedirectUrl } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { isProd } from '@/util/environment';
import { getValueFromJsonString } from '@/util/json';
import { pathnameEndsWith, pathnameIncludes } from '@/util/location';
import { isModeProduction } from '@/util/modes';
import type { MainMenuItem, MenuSectionType } from '@/zustand/AppFrameStore';
import { MainMenuKeys, useAppFrameStore } from '@/zustand/AppFrameStore';
import { useReactiveVar } from '@apollo/client';
import { AnnouncementSharp, Collections } from '@mui/icons-material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BusinessIcon from '@mui/icons-material/Business';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import UserGroup from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import EncountersIcon from '@mui/icons-material/InterpreterModeOutlined';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BillingIcon from '@mui/icons-material/PaymentOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PhoneIcon from '@mui/icons-material/Phone';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import QueueIcon from '@mui/icons-material/Queue';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReviewsIcon from '@mui/icons-material/Reviews';
import RouteIcon from '@mui/icons-material/Route';
import SearchIcon from '@mui/icons-material/Search';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import type { StackProps } from '@mui/material';
import { Breadcrumbs, Stack } from '@mui/material';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMatches } from 'react-router-dom';
import Navbar from './Navbar';
import NavigationSidebarMenu from './NavigationSidebarMenu';
import VersionFooter from './VersionFooter';

type TimesheetsAndInvoiceMenuItemsProps = {
  isPharmacist: boolean;
  isMTMViewable: boolean;
};

const CreateRecentPatientsMenuItems = (recentPatients: RecentPatientType[], currentUser: LoggedUser | null) => {
  return recentPatients
    .map(patient => {
      const redirectUrl =
        getAccountRedirectUrl(
          patient.truentityId,
          MedicalServices.RPM,
          patient.rpmStatus,
          patient?.accountsMonitoringDevices ?? [],
          currentUser
        ) || '';
      return {
        navigatePath: redirectUrl,
        icon: <PeopleIcon fontSize={'small'} />,
        label: patient.name,
        hidden: false,
        selected: pathnameIncludes(redirectUrl),
        showOnTopMenu: true
      };
    })
    .filter(item => item.navigatePath !== '');
};

const CreateTimesheetsAndInvoiceMenuItems = ({ isPharmacist, isMTMViewable }: TimesheetsAndInvoiceMenuItemsProps): MainMenuItem[] => {
  return [
    {
      navigatePath: '../administration/timesheets',
      icon: <TaskAltIcon />,
      label: isPharmacist ? 'My Timesheets' : 'Timesheets',
      hidden: !isMTMViewable,
      selected: pathnameIncludes('administration/timesheets')
    },
    {
      navigatePath: '../administration/invoices',
      icon: <MonetizationOnIcon />,
      label: isPharmacist ? 'My Invoices' : 'Invoices',
      hidden: !isMTMViewable,
      selected: pathnameIncludes('administration/invoices')
    }
  ].filter(item => !item.hidden);
};

const CreatePatientsMenuItems = ({ isRPMViewable, currentUser }) => {
  return [
    {
      navigatePath: `../patients?search=${PatientsListSearchFilters.Lookup}`,
      icon: <SearchIcon />,
      label: 'Patients Lookup',
      hidden: false,
      selected: pathnameIncludes(PatientsListSearchFilters.Lookup)
    },
    {
      navigatePath: `../patients?search=${PatientsListSearchFilters.ToCallNext}`,
      icon: <PhoneIcon />,
      label: 'Patients to Follow Up',
      hidden: false,
      selected: pathnameIncludes(PatientsListSearchFilters.ToCallNext)
    },
    {
      navigatePath: `../patients?search=${currentUser?.id}`,
      icon: <AssignmentIndIcon />,
      label: 'My Patients',
      hidden: isRPMViewable,
      ribbonPosition: 0,
      selected: pathnameIncludes(`patients?search=${currentUser?.id}`)
    },
    {
      navigatePath: `../patients/conversations`,
      icon: <ChatBubbleOutlineIcon />,
      label: 'Conversations',
      ribbonPosition: 5,
      hidden: false,
      selected: pathnameIncludes('patients/conversations')
    },
    {
      navigatePath: '../patients/med-history-requests',
      icon: <DashboardIcon />,
      label: 'Med History',
      hidden: false,
      ribbonPosition: 4,
      selected: pathnameIncludes('../patients/med-history-requests')
    }
  ].filter(item => !item.hidden);
};

const CreateMedicationManagementMenuItems = ({ isAnyAdmin }) => {
  return [
    {
      navigatePath: '../tasks',
      icon: <TaskAltIcon />,
      label: isAnyAdmin ? 'Tasks' : 'My Tasks',
      hidden: false,
      selected: pathnameIncludes('/tasks')
    },
    {
      navigatePath: '../reports/my-dashboard',
      icon: <DashboardIcon />,
      label: 'My Dashboard',
      hidden: false,
      selected: pathnameIncludes('/reports/my-dashboard')
    }
  ].filter(item => !item.hidden);
};

const CreateRemotePatientMonitoringMenuItems = ({ isSuperAdmin, isProvider, currentUser }) => {
  const isPatientScheduleViewable = getValueFromJsonString(currentUser?.settings, 'additional_permission.can_view_all_patient_schedules');
  return [
    {
      navigatePath: '../remote-patient-monitoring/dashboard',
      icon: <DashboardIcon fontSize="small" />,
      label: 'Dashboard',
      hidden: false,
      selected: pathnameIncludes('/remote-patient-monitoring/dashboard')
    },
    {
      navigatePath: '../remote-patient-monitoring/my-patients',
      icon: <PeopleIcon fontSize="small" />,
      label: 'My Patients',
      ribbonPosition: 0,
      hidden: false,
      selected: pathnameIncludes('/remote-patient-monitoring/my-patients')
    },
    {
      navigatePath: '../remote-patient-monitoring/provider-approvals',
      icon: <AssignmentTurnedInIcon fontSize="small" />,
      label: 'Provider Approvals',
      ribbonPosition: -1,
      hidden: false,
      selected: pathnameIncludes('/remote-patient-monitoring/provider-approvals')
    },
    {
      navigatePath: '../remote-patient-monitoring/alerts',
      icon: <NotificationsIcon />,
      label: 'Alerts',
      ribbonPosition: 1,
      hidden: false,
      selected: pathnameIncludes('/remote-patient-monitoring/alerts')
    },
    {
      navigatePath: '../remote-patient-monitoring/follow-up-tasks',
      icon: <TaskAltIcon fontSize="small" />,
      label: 'Follow-up Tasks',
      ribbonPosition: 2,
      hidden: false,
      selected: pathnameIncludes('/remote-patient-monitoring/follow-up-tasks'),
      buttonProps: {
        sx: {
          textTransform: 'none'
        }
      }
    },
    {
      navigatePath: '../remote-patient-monitoring/devices',
      icon: <DevicesOtherIcon />,
      label: 'Devices',
      ribbonPosition: 6,
      hidden: false,
      selected: pathnameIncludes('/remote-patient-monitoring/devices')
    },
    {
      navigatePath: '../remote-patient-monitoring/reports-communication',
      icon: <ImportExportIcon />,
      label: 'Reports Communication',
      ribbonPosition: 3,
      hidden: false,
      selected: pathnameIncludes('/remote-patient-monitoring/reports-communication')
    },
    {
      navigatePath: '../remote-patient-monitoring/patient-schedules',
      icon: <HistoryToggleOffIcon />,
      label: 'Patient Schedules',
      ribbonPosition: 9,
      hidden: !(isSuperAdmin || isProvider || isPatientScheduleViewable),
      selected: pathnameIncludes('/remote-patient-monitoring/patient-schedules')
    },
    {
      navigatePath: '../remote-patient-monitoring/claims',
      icon: <MonetizationOnIcon />,
      label: 'Claims',
      hidden: !isSuperAdmin,
      selected: pathnameIncludes('/remote-patient-monitoring/claims')
    },
    {
      navigatePath: '../remote-patient-monitoring/review-patients',
      icon: <ReviewsIcon />,
      label: 'Chart Review',
      ribbonPosition: 3,
      hidden: !(isProvider || isSuperAdmin),
      selected: pathnameIncludes('/remote-patient-monitoring/review-patients')
    }
  ].filter(item => !item.hidden);
};

const CreateTcmMenuItems = ({ isSuperAdmin, isPharmacistAdmin, isADTAdmin }) => {
  return [
    {
      navigatePath: '../transitional-care-management/dashboard',
      icon: <DashboardIcon fontSize="small" />,
      label: 'Dashboard',
      hidden: !(isSuperAdmin || isPharmacistAdmin),
      selected: pathnameIncludes('/transitional-care-management/dashboard')
    },
    {
      navigatePath: '../transitional-care-management/adt-queue-v1',
      icon: <QueueIcon />,
      label: 'ADT Queue',
      hidden: !(isSuperAdmin || isPharmacistAdmin),
      selected: pathnameIncludes('/transitional-care-management/adt-queue-v1')
    },
    {
      navigatePath: '../transitional-care-management/tcm-tasks',
      icon: <AssignmentTurnedInIcon />,
      label: 'TCM Tasks',
      hidden: !(isSuperAdmin || isPharmacistAdmin),
      selected: pathnameIncludes('/transitional-care-management/tcm-tasks')
    },
    {
      navigatePath: '../transitional-care-management/discharge',
      icon: <QueueIcon />,
      label: 'Discharge Patients',
      hidden: !isADTAdmin,
      selected: pathnameIncludes('/transitional-care-management/discharge')
    },
    {
      navigatePath: '../transitional-care-management/billing',
      icon: <MonetizationOnIcon />,
      label: 'Billing & Claims',
      hidden: false,
      selected: pathnameIncludes('/transitional-care-management/billing')
    }
  ].filter(item => !item.hidden);
};

const CreateReportMenuItems = ({ isSuperAdmin, isRPMViewable, isPharmacistAdmin }) => {
  return [
    {
      navigatePath: '../reports/dashboard',
      icon: <DashboardIcon fontSize="small" />,
      label: 'Dashboard',
      hidden: false,
      selected: pathnameIncludes('/reports/dashboard')
    },
    {
      navigatePath: '../reports/billing',
      icon: <BillingIcon fontSize="small" />,
      label: 'Billing',
      hidden: false,
      selected: pathnameIncludes('/reports/billing')
    },
    {
      navigatePath: '../reports/encounters',
      icon: <EncountersIcon fontSize="small" />,
      label: 'Encounters',
      hidden: false,
      selected: pathnameIncludes('/reports/encounters')
    },
    {
      navigatePath: '../reports/tasks',
      icon: <TaskAltIcon />,
      label: 'Tasks',
      hidden: false,
      selected: pathnameIncludes('/reports/tasks')
    },
    {
      navigatePath: '../reports/health-plan',
      icon: <HealthAndSafetyIcon fontSize="small" />,
      label: 'Health Plan',
      hidden: false,
      selected: pathnameIncludes('/reports/health-plan')
    },
    {
      navigatePath: '../reports/performance',
      icon: <TrendingUpIcon />,
      label: 'Performance',
      hidden: !isSuperAdmin,
      selected: pathnameIncludes('/reports/performance')
    },
    {
      navigatePath: '../reports/rpm-billing',
      icon: <MonetizationOnIcon />,
      label: 'RPM Billing',
      hidden: !(isSuperAdmin && isRPMViewable),
      selected: pathnameIncludes('/reports/rpm-billing')
    },
    {
      navigatePath: '../reports/rpm-providers',
      icon: <LocalHospitalIcon />,
      label: 'RPM Providers',
      hidden: !((isSuperAdmin || isPharmacistAdmin) && isRPMViewable),
      selected: pathnameIncludes('/reports/rpm-providers')
    },
    {
      navigatePath: '../reports/rpm-companies',
      icon: <LocalPharmacyIcon />,
      label: 'RPM Companies',
      hidden: !(isSuperAdmin && isRPMViewable),
      selected: pathnameIncludes('/reports/rpm-companies')
    }
  ].filter(item => !item.hidden);
};

const CreateUserAdminMenuItems = ({ isPharmacist, currentUser }) => {
  const parseSettings = settings => {
    try {
      return JSON.parse(settings);
    } catch (error) {
      console.error('Failed to parse settings:', error);
      return {};
    }
  };
  const isUserManageAvailable = parseSettings(currentUser?.settings)?.['additional_permission']?.['can_manage_users'] ?? false;

  return [
    {
      navigatePath: '../administration/dashboard',
      icon: <DashboardIcon fontSize="small" />,
      label: 'Status',
      hidden: isPharmacist,
      selected: pathnameIncludes('/administration/dashboard')
    },
    {
      navigatePath: '../administration/users',
      icon: <UserGroup fontSize="small" />,
      label: 'Manage',
      hidden: !isPharmacist ? false : !isUserManageAvailable,
      selected: pathnameIncludes('/administration/users')
    }
  ].filter(item => !item.hidden);
};

const CreateAdminMenuItems = ({ isSuperAdmin, isPharmacistAdmin }) => {
  return [
    {
      navigatePath: '../administration/organizations',
      icon: <BusinessIcon fontSize="small" />,
      label: 'Organizations',
      hidden: false,
      selected: pathnameIncludes('/administration/organizations')
    },
    {
      navigatePath: '../administration/patients',
      icon: <GroupsIcon />,
      label: 'Patients',
      hidden: !isSuperAdmin,
      selected: pathnameIncludes('/administration/patients')
    },
    {
      navigatePath: '../administration/health-plans',
      icon: <HealthAndSafetyIcon />,
      label: 'Health Plans',
      hidden: false,
      selected: pathnameIncludes('/administration/health-plans')
    },
    {
      navigatePath: '../administration/resources',
      icon: <Collections />,
      label: 'Resources',
      hidden: false,
      ribbonPosition: 8,
      selected: pathnameIncludes('/administration/resources')
    },
    {
      navigatePath: '../administration/providers',
      icon: <LocalHospitalIcon />,
      label: 'Providers',
      hidden: false,
      ribbonPosition: 7,
      selected: pathnameIncludes('/administration/providers')
    },
    {
      navigatePath: '../administration/my-performance',
      icon: <QueryStatsIcon />,
      label: 'My Performance',
      hidden: !isPharmacistAdmin,
      selected: pathnameIncludes('/administration/my-performance')
    },
    {
      navigatePath: '../administration/logs',
      icon: <ReceiptLongIcon />,
      label: 'Logs',
      hidden: false,
      selected: pathnameEndsWith('/administration/logs')
    },
    {
      navigatePath: '../administration/announcements',
      icon: <AnnouncementSharp />,
      label: 'Announcements',
      hidden: false,
      selected: pathnameEndsWith('/administration/announcements')
    },
    {
      navigatePath: '../administration/mock-apis',
      icon: <RouteIcon />,
      label: 'Athena APIs',
      hidden: !isSuperAdmin,
      selected: pathnameEndsWith('/administration/mock-apis')
    }
  ].filter(item => !item.hidden);
};

const CreateSystemAdminMenuItems = ({ isSuperAdmin, currentUser }: { isSuperAdmin: boolean; currentUser: LoggedUser | null }) => {
  return [
    {
      navigatePath: '../administration/system/notification-files',
      icon: <MonitorHeartIcon />,
      label: 'Notification Files',
      hidden: false,
      selected: pathnameIncludes('/administration/system/notification-files')
    },
    {
      navigatePath: `../administration/system/companies/${currentUser?.relyingParty.id}`,
      icon: <BusinessIcon />,
      label: 'My Company',
      hidden: isSuperAdmin,
      selected: pathnameEndsWith(`/administration/system/companies/${currentUser?.relyingParty.id}`)
    },
    {
      navigatePath: '../administration/system/companies',
      icon: <BusinessIcon />,
      label: 'All Companies',
      hidden: !isSuperAdmin,
      selected: pathnameEndsWith('/administration/system/companies')
    }
  ].filter(item => !item.hidden);
};

const CreateQuickLinksMenuItems = ({ isPharmacist, isMTMViewable, isRPMViewable }) => {
  return [
    {
      navigatePath: '/administration/my-performance',
      icon: <QueryStatsIcon />,
      label: 'My Performance',
      hidden: !(isPharmacist && isMTMViewable),
      selected: pathnameIncludes('/administration/my-performance')
    },
    {
      navigatePath: '../administration/resources',
      icon: <Collections />,
      label: 'Resources',
      ribbonPosition: 8,
      hidden: !isPharmacist,
      selected: pathnameIncludes('/administration/resources')
    },
    {
      navigatePath: '/administration/providers',
      icon: <LocalHospitalIcon />,
      label: 'Providers',
      ribbonPosition: 7,
      hidden: !(isPharmacist && isRPMViewable),
      selected: pathnameIncludes('/administration/providers')
    },
    {
      navigatePath: '../administration/logs',
      icon: <ReceiptLongIcon />,
      label: 'Logs',
      hidden: !isPharmacist,
      selected: pathnameEndsWith('/administration/logs')
    },
    {
      navigatePath: '../administration/announcements',
      icon: <AnnouncementSharp />,
      label: 'Announcements',
      hidden: !isPharmacist,
      selected: pathnameEndsWith('/administration/announcements')
    }
  ].filter(item => !item.hidden);
};
type ContentDivProps = StackProps & {
  goTop: boolean;
  setGoTop: (value: boolean) => void;
  setContentScrollPosition: (value: number) => void;
  ribbonMenuItems: MainMenuItem[];
};
const ContentDiv = ({ goTop, setContentScrollPosition, setGoTop, ribbonMenuItems, children, ...props }: ContentDivProps) => {
  const { ref, top } = useScrollBarPosition();

  const matches = useMatches();
  const crumbs = matches
    .filter(match => match.handle && match.handle.crumb)
    .map(match => {
      return match?.handle?.crumb(match);
    });

  useEffect(() => {
    if (top >= 0) {
      setContentScrollPosition(+top);
    }
  }, [setContentScrollPosition, top]);

  useEffect(() => {
    if (goTop) {
      setGoTop(false);
      ref.current?.scrollTo({ top: 0 });
    }
  }, [goTop, ref, setGoTop]);

  return (
    <Stack ref={ref} {...props}>
      {crumbs.length > 1 && (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} pt={2} pb={4}>
          <Breadcrumbs
            sx={{
              flex: 1
            }}
          >
            {crumbs.map((crumb, index) => (
              <span key={index}>{crumb}</span>
            ))}
          </Breadcrumbs>
          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <BreadcrumbRibbonMenu items={ribbonMenuItems} />
            <StarredPatientsList />
          </Stack>
        </Stack>
      )}
      {children}
    </Stack>
  );
};

type Props = {
  children: ReactNode;
};
const AppFrame = ({ children }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [reloadAccounts, setReloadAccounts] = useState<Date | null>(null);

  const drawerToggle = useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [drawerOpen]);

  const [content, setContent] = useState<ReactNode>(null);
  const drawerWidth = 360;

  const { setContentScrollPosition, goTop, setGoTop, setMainMenuItems, mainMenuItems } = useAppFrameStore();

  const { roleType, getRecentPatients } = useToken();
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);
  const [isPharmacistAdmin] = useState<boolean>(roleType === Role.PHARMACIST_ADMIN);
  const [isPharmacist] = useState<boolean>(roleType === Role.PHARMACIST);
  const [isADTAdmin] = useState<boolean>(roleType === Role.ADT_ADMIN);
  const [isProvider] = useState<boolean>(roleType === Role.PROVIDER);
  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);
  const isMTMViewable = useShowMedicalServices(MedicalServices.MTM);
  const isTCMViewable = useShowMedicalServices(MedicalServices.TCM);
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const [recentPatients, setRecentPatients] = useState<RecentPatientType[]>([]);
  const isAnyAdmin = useMemo(() => isSuperAdmin || isPharmacistAdmin || isADTAdmin, [isADTAdmin, isPharmacistAdmin, isSuperAdmin]);

  const [ribbonMenuItems, setRibbonMenuItems] = useState<MainMenuItem[]>([]);

  const providerValue: SideMenuContextProps = useMemo(
    () => ({
      drawerOpen,
      setDrawerOpen,
      drawerToggle,
      setContent,
      content,
      reloadAccounts,
      setReloadAccounts
    }),
    [drawerOpen, setDrawerOpen, drawerToggle, setContent, content, reloadAccounts, setReloadAccounts]
  );

  useEffect(() => {
    const menuItems = new Map<MainMenuKeys, MenuSectionType>();

    menuItems.set(MainMenuKeys.RecentPatients, {
      title: 'Recent Patients',
      options: []
    });
    menuItems.set(MainMenuKeys.Patients, {
      title: 'Patients',
      options: CreatePatientsMenuItems({ isRPMViewable, currentUser, isProd: isProd() })
    });
    menuItems.set(MainMenuKeys.MedicationManagement, {
      title: 'Medication Management',
      options: CreateMedicationManagementMenuItems({ isAnyAdmin }),
      hidden: !isMTMViewable
    });
    menuItems.set(MainMenuKeys.RemotePatientMonitoring, {
      title: 'Remote Patient Monitoring',
      options: CreateRemotePatientMonitoringMenuItems({ isSuperAdmin, isProvider, currentUser }),
      hidden: !isRPMViewable
    });
    menuItems.set(MainMenuKeys.TimesheetsInvoicesAdmin, {
      title: 'Timesheets & Invoices',
      options: CreateTimesheetsAndInvoiceMenuItems({ isPharmacist, isMTMViewable }),
      hidden: !(isAnyAdmin || isMTMViewable || isRPMViewable)
    });
    menuItems.set(MainMenuKeys.TransitionalCareManagement, {
      title: 'Transitional Care Management',
      options: CreateTcmMenuItems({ isPharmacistAdmin, isADTAdmin, isSuperAdmin }),
      hidden: !(!isModeProduction() && isAnyAdmin && isTCMViewable)
    });

    menuItems.set(MainMenuKeys.Reports, {
      title: 'Reports',
      options: CreateReportMenuItems({ isSuperAdmin, isRPMViewable, isPharmacistAdmin }),
      hidden: !isAnyAdmin
    });

    menuItems.set(MainMenuKeys.UserAdmin, {
      title: 'User Administration',
      options: CreateUserAdminMenuItems({ isPharmacist, currentUser }),
      hidden: !(isAnyAdmin || isPharmacist)
    });

    menuItems.set(MainMenuKeys.Administration, {
      title: 'Administration',
      options: CreateAdminMenuItems({ isPharmacistAdmin, isSuperAdmin }),
      hidden: !isAnyAdmin
    });

    menuItems.set(MainMenuKeys.SystemAdmin, {
      title: 'System Administration',
      options: CreateSystemAdminMenuItems({ isSuperAdmin, currentUser }),
      hidden: !isAnyAdmin
    });

    menuItems.set(MainMenuKeys.QuickLinksAdmin, {
      title: 'Quick Links',
      options: CreateQuickLinksMenuItems({ isMTMViewable, isRPMViewable, isPharmacist }),
      hidden: !(isAnyAdmin || isMTMViewable || isRPMViewable)
    });

    setMainMenuItems(menuItems);
  }, [
    currentUser,
    isADTAdmin,
    isAnyAdmin,
    isMTMViewable,
    isPharmacist,
    isPharmacistAdmin,
    isRPMViewable,
    isSuperAdmin,
    isTCMViewable,
    setMainMenuItems
  ]);

  useEffect(() => {
    const ribbonItems = Array.from(mainMenuItems.values())
      .filter(menuSection => !menuSection.hidden)
      .flatMap(menuSection => menuSection.options)
      .filter(v => v.ribbonPosition > -1)
      .sort((a, b) => a.ribbonPosition - b.ribbonPosition);

    setRibbonMenuItems(ribbonItems);
  }, [mainMenuItems]);

  useEffect(() => {
    const patients = getRecentPatients();
    setRecentPatients(patients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    useAppFrameStore.setState(prev => ({
      mainMenuItems: new Map<MainMenuKeys, MenuSectionType>(prev.mainMenuItems).set(MainMenuKeys.RecentPatients, {
        title: 'Recent Patients',
        options: CreateRecentPatientsMenuItems(recentPatients, currentUser)
      })
    }));
    //need this so we can keep recent patients up-to-date

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentPatients, currentUser]);

  return (
    <SideMenuContext.Provider value={providerValue}>
      <Stack sx={{ width: '100vw', height: '100%' }}>
        <Announcement />
        <Navbar elevation={0} enableColorOnDark color="inherit" sx={{ height: '85px', zIndex: 11 }} />
        <Stack direction={'row'} sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
          <NavigationSidebarMenu drawerWidth={drawerWidth} sx={{ width: drawerOpen ? drawerWidth : 'auto' }} />
          <ContentDiv
            {...{ goTop, setContentScrollPosition, setGoTop, ribbonMenuItems }}
            sx={{
              padding: theme => theme.spacing(3, 3, 3, drawerOpen ? 6 : 12),
              width: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)`,
              flex: 1
            }}
          >
            {children}
          </ContentDiv>
        </Stack>

        <VersionFooter sx={{ height: '44px' }} />
      </Stack>
    </SideMenuContext.Provider>
  );
};

export default AppFrame;
