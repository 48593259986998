import type { MonthlyResponse } from '@/graphql/remotePatientMonitoring';
import { SAVE_PATIENT_REVIEW_NOTE } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import type { GeneralUpdateResponseType } from '@/types/graphql';
import type { DynamicTemplateType } from '@/types/remotePatientMonitoring';
import { ReviewNoteCategoryType } from '@/types/remotePatientMonitoring';
import { replaceTemplatePlaceholders } from '@/util/rpm';
import { useMutation } from '@apollo/client';
import SendIcon from '@mui/icons-material/Send';
import { Box, CardActions, Chip, CircularProgress, IconButton, Stack, TextField, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState, type Dispatch, type FC, type SetStateAction } from 'react';
import { Controller, useForm } from 'react-hook-form';
import RpmPatientReviewAssessmentInput from './RpmPatientReviewAssessmentInput';

type Props = {
  reportMonth: MonthlyResponse | null;
  isTemplateDrawerOpen: boolean;
  assessment: DynamicTemplateType | null;
  setAssessment: Dispatch<SetStateAction<DynamicTemplateType | null>>;
  handleTemplateButton: () => void;
};

type FormMode = ReviewNoteCategoryType.ASSESSMENT | ReviewNoteCategoryType.INTERVENTION;

const RpmPatientReviewWorkflowWindowAction: FC<Props> = ({
  reportMonth,
  isTemplateDrawerOpen,
  assessment,
  setAssessment,
  handleTemplateButton
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [formMode, setFormMode] = useState<FormMode>(ReviewNoteCategoryType.INTERVENTION);
  const [savePatientReviewNote, { loading: loadingSavePatientReviewNote }] = useMutation<{
    createAccountReviewNote: GeneralUpdateResponseType;
  }>(SAVE_PATIENT_REVIEW_NOTE);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm<{ note: string }>({ mode: 'onChange', defaultValues: { note: '' } });

  const isAllAssessmentFilled = assessment?.inputs.every(input => input.value.trim().length > 0) || false;
  const isFormModeIsIntervention = formMode === ReviewNoteCategoryType.INTERVENTION;
  const isFormModeIsAssessment = formMode === ReviewNoteCategoryType.ASSESSMENT;

  const handleSendNote = async (data: { note: string }) => {
    const note = assessment ? replaceTemplatePlaceholders(assessment) : data.note;
    const category = isFormModeIsAssessment ? ReviewNoteCategoryType.ASSESSMENT : ReviewNoteCategoryType.INTERVENTION;

    try {
      const response = await savePatientReviewNote({
        variables: {
          accountReviewWorkflowId: reportMonth?.report?.rpmAccountReviewWorkflow?.id,
          note: note,
          category: category
        }
      });

      if (response?.data?.createAccountReviewNote?.status === 'Success') {
        if (assessment) setAssessment(null);
        enqueueSnackbar('Note saved successfully', {
          variant: 'success'
        });
        reset();
      }
    } catch (err: unknown) {
      let errorMessage = 'Something went wrong. Could not save note.';
      if (err instanceof Error) {
        errorMessage = err?.message ?? 'Something went wrong. Could not save note.';
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    }
  };

  const handleChipClick = (type: FormMode) => {
    setFormMode(type);
    if (type === ReviewNoteCategoryType.ASSESSMENT) handleTemplateButton();
    if (type === ReviewNoteCategoryType.INTERVENTION) {
      setAssessment(null);
      if (isTemplateDrawerOpen) handleTemplateButton();
    }
  };

  return (
    <CardActions sx={{ p: 1, bgcolor: color.primaryLight, gap: 0.5 }}>
      <Stack direction="row" component="form" onSubmit={handleSubmit(handleSendNote)} alignItems="center" flexGrow={1} autoComplete="off">
        <Stack
          width="100%"
          bgcolor="white"
          p={1}
          gap={1}
          justifyContent="flex-start"
          alignItems="flex-start"
          borderRadius={1}
          sx={{
            cursor: 'text',
            outline: 'none',
            ':hover': {
              boxShadow: `0 0 0 2px ${theme.palette.grey[700]}`
            },
            transition: 'box-shadow 0.3s',
            '&:focus-within': {
              boxShadow: `0 0 0 2px ${theme.palette.primary.dark}`
            }
          }}
        >
          {assessment ? (
            <Box maxHeight="100px" overflow="auto">
              <RpmPatientReviewAssessmentInput assessment={assessment} setAssessment={setAssessment} />
            </Box>
          ) : (
            <Controller
              control={control}
              name="note"
              rules={{ required: 'Review Note is required' }}
              render={({ field }) => (
                <TextField
                  id="note"
                  multiline
                  maxRows={3}
                  autoFocus
                  placeholder={
                    isFormModeIsAssessment ? 'Select assessment template or type custom assessment note' : 'Type a Intervention Note...'
                  }
                  size="small"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true
                  }}
                  fullWidth
                  required
                  {...field}
                />
              )}
            />
          )}
          <Stack direction="row" gap={1}>
            <Chip
              label="Add Assessment"
              size="small"
              variant={isFormModeIsAssessment ? 'filled' : 'outlined'}
              color={isFormModeIsAssessment ? 'success' : 'primary'}
              onClick={() => handleChipClick(ReviewNoteCategoryType.ASSESSMENT)}
            />
            <Chip
              label="Add Intervention"
              size="small"
              variant={isFormModeIsIntervention ? 'filled' : 'outlined'}
              color={isFormModeIsIntervention ? 'success' : 'primary'}
              onClick={() => handleChipClick(ReviewNoteCategoryType.INTERVENTION)}
            />
          </Stack>
        </Stack>
        {loadingSavePatientReviewNote ? (
          <CircularProgress color="primary" />
        ) : (
          <IconButton type="submit" disabled={!(isAllAssessmentFilled || isValid)}>
            <SendIcon fontSize="small" />
          </IconButton>
        )}
      </Stack>
    </CardActions>
  );
};

export default RpmPatientReviewWorkflowWindowAction;
