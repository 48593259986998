import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { FollowupFormModeTypes } from '@/components/Dialogs/FollowUpAddEditDialog';
import FollowUpTaskAddEditDialog from '@/components/Dialogs/FollowUpTaskAddEditDialog';
import FollowUpTaskInfoDialog from '@/components/Dialogs/FollowUpTaskInfoDialog';
import type { TabContent } from '@/components/MuiTabs';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import { H1 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import type { GetFilteredRelyingPartyAdminsResponse } from '@/graphql/account';
import {
  FollowUpCategoryTypes,
  FollowUpStatusTypes,
  FollowUpTaskTypes,
  FollowUpTypes,
  GET_FILTERED_RELYING_PARTY_ADMINS
} from '@/graphql/account';
import type { RelyingPartyAdminFollowUpResponse } from '@/graphql/remotePatientMonitoring';
import { CURRENT_RELYING_PARTY_ADMIN_FOLLOWUPS } from '@/graphql/remotePatientMonitoring';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { formatDateAndTime } from '@/util/format';
import { getAlertTypeStyles } from '@/util/rpm';
import { convertToSentenceCase, formatFromSnakeCase, unknown } from '@/util/string';
import { useQuery, useReactiveVar } from '@apollo/client';
import { ErrorOutlineRounded } from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Chip, IconButton, Paper, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import type { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import type { SyntheticEvent } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { RelyingPartyAdminData } from '../Administration';

const followUpTasksTabs: TabContent[] = Object.values(FollowUpStatusTypes).map(type => ({
  key: type,
  label: convertToSentenceCase(type)
}));

const columnVisibility: Record<FollowUpStatusTypes, Record<string, boolean>> = {
  PENDING: {
    actions: true
  },
  COMPLETED: {
    actions: false
  }
};

const FollowUpsTask = () => {
  const { showModal } = useModal();
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<TabContent>(followUpTasksTabs[activeTabIndex]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(columnVisibility.PENDING);
  const [selectedAssignedAdmin, setSelectedAssignedAdmin] = useState<string>('All');
  const [relyingPartyAdmins, setRelyingPartyAdmins] = useState<RelyingPartyAdminData[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('All');

  const { setContent, setDrawerOpen } = useContext(SideMenuContext);

  const {
    data: currentAdminFollowUps,
    loading: isCurrentAdminFollowUpsLoading,
    refetch: refetchCurrentAdminFollowUp
  } = useQuery<RelyingPartyAdminFollowUpResponse>(CURRENT_RELYING_PARTY_ADMIN_FOLLOWUPS, {
    variables: {
      pageNum: currentPage,
      pageSize: DEFAULT_PAGE_SIZE,
      filterOptions: {
        relyingPartyAdminId: selectedAssignedAdmin,
        status: activeTab?.key || FollowUpStatusTypes.PENDING,
        category:
          selectedCategory.toLocaleLowerCase() === 'all'
            ? [FollowUpCategoryTypes.ALERT_REVIEW, FollowUpCategoryTypes.CHART_REVIEW]
            : [selectedCategory.toUpperCase()]
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  useQuery<GetFilteredRelyingPartyAdminsResponse>(GET_FILTERED_RELYING_PARTY_ADMINS, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      excludedRoles: ['SUPER_ADMIN']
    },
    onCompleted: data => {
      setRelyingPartyAdmins(data?.filteredRelyingPartyAdmins?.relyingPartyAdmins || []);
    }
  });

  const handleTabChange = (_event: SyntheticEvent, selectedTabIndex: number) => {
    const activeTab = followUpTasksTabs[selectedTabIndex];

    setActiveTabIndex(selectedTabIndex);
    setActiveTab(activeTab);

    if (!activeTab.key) return;
    setColumnVisibilityModel(columnVisibility[activeTab.key]);
  };

  const handleCheckButton = useCallback(
    (data: FollowUpRemindersTypes) => {
      const { hide } = showModal(FollowUpTaskInfoDialog, {
        title: 'Follow-up Task Information',
        hideDialog: () => hide(),
        followUpId: data.id,
        onCompleted: () => {
          hide();
          refetchCurrentAdminFollowUp();
        }
      });
    },
    [refetchCurrentAdminFollowUp, showModal]
  );

  const handleEditButton = useCallback(
    async (followUpData: FollowUpRemindersTypes) => {
      const modal = showModal(FollowUpTaskAddEditDialog, {
        title: 'Edit Alert Review Task',
        accountAssignees: relyingPartyAdmins,
        category: followUpData.category,
        hideDialog: () => modal.hide(),
        onUpdated: () => {
          modal.hide();
          refetchCurrentAdminFollowUp();
        },
        formMode: FollowupFormModeTypes.UPDATE,
        followUpTaskData: followUpData
      });
    },
    [refetchCurrentAdminFollowUp, relyingPartyAdmins, showModal]
  );

  const renderAlertLabelChip = useCallback((value: string) => {
    const alertTypeStyles = getAlertTypeStyles(value);

    if (alertTypeStyles?.labelColor) {
      return (
        <Chip
          icon={
            <ErrorOutlineRounded
              sx={{
                color: `${alertTypeStyles?.labelColor} !important`
              }}
            />
          }
          variant="outlined"
          label={alertTypeStyles?.labelText}
          sx={{
            borderColor: alertTypeStyles?.labelColor,
            color: alertTypeStyles?.labelColor
          }}
        />
      );
    }
  }, []);

  const isTaskEditable = useCallback(
    (id: string) => {
      return id === currentUser?.id;
    },
    [currentUser?.id]
  );

  const assignedOptions: TextSelectOption[] = useMemo(() => {
    const options = relyingPartyAdmins.map(admin => ({ label: admin.name, value: admin.id }));

    if (currentUser?.roleType === 'SUPER_ADMIN') {
      options.unshift({ label: getAccountUserFullName(currentUser.user), value: currentUser.id });
    }

    options.unshift({ label: 'All', value: 'all' });

    return options;
  }, [currentUser?.id, currentUser?.roleType, currentUser?.user, relyingPartyAdmins]);

  const taskCategories: TextSelectOption[] = useMemo(() => {
    const options = Object.values(FollowUpCategoryTypes)
      .filter(category => category !== FollowUpCategoryTypes.GENERAL)
      .map(category => ({
        label: formatFromSnakeCase(category) as string,
        value: category as string
      }));

    options.unshift({ label: 'All', value: 'all' });
    return options;
  }, []);

  const columns: GridColDef<FollowUpRemindersTypes>[] = useMemo(
    () => [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => getAccountUserFullName(params.row?.account?.user)
      },
      {
        field: 'Task Type',
        headerName: 'Task Type',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => {
          if (params.row.type === FollowUpTypes.CALL_PATIENT) {
            return FollowUpTaskTypes.CONTACT_PATIENT;
          }
          return params.row.type;
        }
      },
      {
        field: 'category',
        headerName: 'Category',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => formatFromSnakeCase(params.value)
      },
      {
        field: 'label',
        headerName: 'Alert Type',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.rpmAlert?.label || unknown(),
        renderCell: params => renderAlertLabelChip(params.value)
      },
      {
        field: 'taskDateAndTime',
        headerName: 'Task Due at',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDateAndTime(params.row.followUpOn)
      },
      {
        field: 'assignedTo',
        headerName: 'Assigned To',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => getAccountUserFullName(params.row?.relyingPartyAdmin?.user)
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          const isDisabled = !isTaskEditable(params.row.relyingPartyAdmin.id);

          const editTooltipText = isDisabled
            ? `You cannot edit this follow-up, because it is assigned to another assignee`
            : 'Edit Follow-up';
          const completeTooltipText = isDisabled
            ? `You cannot mark this follow-up as completed, because it is assigned to another assignee`
            : 'Complete Follow-up';

          return (
            <Stack direction="row">
              <Tooltip title={completeTooltipText}>
                <span>
                  <IconButton onClick={() => handleCheckButton(params.row)} disabled={isDisabled}>
                    <TaskAltIcon color={!isDisabled ? 'info' : 'disabled'} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={editTooltipText}>
                <span>
                  <IconButton onClick={() => handleEditButton(params.row)} disabled={isDisabled}>
                    <ModeEditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          );
        }
      }
    ],
    [handleCheckButton, handleEditButton, isTaskEditable, renderAlertLabelChip]
  );

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent, setDrawerOpen]);

  return (
    <Stack spacing={1}>
      <Stack
        component={Paper}
        spacing={2}
        direction="row"
        alignItems="center"
        elevation={0}
        sx={{
          padding: 3,
          borderRadius: '8px'
        }}
      >
        <H1
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '32px',
            flex: 1,
            textAlign: 'left'
          }}
        >
          Follow-up Tasks
        </H1>
        <SelectList
          label="Assigned To"
          options={assignedOptions}
          placeholder="Select an option..."
          value={selectedAssignedAdmin}
          formControlProps={{ sx: { minWidth: '300px' } }}
          fullWidth={false}
          onChange={event => setSelectedAssignedAdmin(event.target.value as string)}
        />
        <SelectList
          label="Task Category"
          options={taskCategories}
          placeholder="Select an option..."
          value={selectedCategory}
          formControlProps={{ sx: { minWidth: '150px' } }}
          fullWidth={false}
          onChange={event => setSelectedCategory(event.target.value as string)}
        />
      </Stack>

      <Paper component={Stack} padding={2} elevation={0} mt={3} spacing={3}>
        <Tabs value={activeTabIndex} onChange={handleTabChange} variant="fullWidth">
          {followUpTasksTabs.map(tab => (
            <Tab label={<h6 style={{ marginBottom: 0 }}>{tab.label}</h6>} key={tab.key} />
          ))}
        </Tabs>
        <TruentityDataGrid
          name={'dg-follow-up-task'}
          autoHeight
          rows={currentAdminFollowUps?.relyingPartyFollowups?.accountFollowups || []}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          loading={isCurrentAdminFollowUpsLoading || false}
          rowCount={currentAdminFollowUps?.relyingPartyFollowups?.meta?.totalCount || 0}
          paginationMode="server"
          rowSelection={false}
        />
      </Paper>
    </Stack>
  );
};

export default FollowUpsTask;
