export type Message = {
  sender: string;
  text: string;
};

export type CopilotConversationTitle = {
  id: string;
  truentityId: string;
  title: string;
  feature: string;
  createdAt: Date;
};

export type CopilotConversationMessage = {
  truentityId: string;
  message: string;
  messageType: string;
};

export enum SenderType {
  BOT = 'bot',
  USER = 'human'
}
