import { Box, keyframes } from '@mui/material';

const bounce = keyframes`
  0%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-4px);
  }
`;

const TypingIndicator = ({ isTyping = false, label = '' }) => {
  if (!isTyping) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        padding: 0.4,
        color: theme => theme.palette.primary[300],
        fontSize: '0.875rem',
        my: '0.5rem'
      }}
    >
      <Box sx={{ display: 'flex', width: 'fit-content', gap: 0.5 }}>
        {[0, 1, 2].map(index => (
          <Box
            key={index}
            sx={{
              width: 5,
              height: 5,
              backgroundColor: theme => theme.palette.primary[400],
              borderRadius: '50%',
              animation: `${bounce} 1.2s ease-in-out infinite`,
              animationDelay: `${index * 0.14}s`
            }}
          />
        ))}
      </Box>
      <span>{label}</span>
    </Box>
  );
};

export default TypingIndicator;
